import React, {
  useEffect, useReducer,
} from 'react'
import {
  Product, Discount,
} from '../../utils/commonInterfaces'
import {
  orderStore, premiseStore,
} from '../../storage'
import {
  TimeslotData, TimeslotError,
} from '../../api/BookingCalendar'
import { HobsProduct } from '../../api/Packages'
import { getBasePrice } from '../../utils/getProductDetails'

  interface Action<N extends string, T> {
    type: N;
    payload: T;
  }

export type OrderAction =
    Action<'select-card', Product> |
    Action<'select-product', Product> |
    Action<'replace-state', OrderState> |
    Action<'submit-personal-details', any> |
    Action<'submit-payment-details', any> |
    Action<'set-discount', Discount> |
    Action<'select-timeslot', TimeslotData | TimeslotError> |
    Action<'set-error', { message: string; step: any }> |
    Action<'timeout-start', number> |
    Action<'set-processing', null>;

function reducer(state: OrderState, action: any): any {
  switch (action.type) {
    case 'select-card':
      return {
        ...state,
        step: 'package',
        error: null,
        product: action.payload,
      }

    case 'select-product':
      return {
        ...state,
        step: 'details',
        error: null,
        product: action.payload,
      }

    case 'replace-state':
      return { ...action.payload }

    case 'submit-personal-details': {
      return {
        ...state,
        step: 'installation',
        error: null,
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    }

    case 'submit-payment-details': {
      return {
        ...state,
        error: null,
        step: 'order',
        data: {
          ...state.data,
          ...action.payload,
        },
      }
    }

    case 'set-discount': {
      return {
        ...state,
        error: null,
        discount: action.payload,
      }
    }

    case 'select-timeslot': {
      const isFree = getBasePrice(state.product!) === '0'

      return {
        ...state,
        step: isFree ? 'order' : 'payment',
        error: null,
        timeslot: action.payload,
      }
    }

    case 'set-error': {
      return {
        ...state,
        step: action.payload.step,
        error: action.payload,
      }
    }

    case 'timeout-start': {
      return {
        ...state,
        countDownTimerStart: action.payload,
      }
    }

    case 'set-processing': {
      return {
        ...state,
        step: 'order-processing',
      }
    }
  }
}

export interface OrderState {
    step: any;
    product: HobsProduct | null;
    discount: Discount | null;
    timeslot: TimeslotData | TimeslotError | null;
    error: any | null;
    account: {id: string} | null;
    countDownTimerStart: number | null;
    data: Partial<any> & Partial<any>;
  }

export const initialState: OrderState = {
  step: 'package',
  product: null,
  account: null,
  discount: null,
  timeslot: null,
  error: null,
  countDownTimerStart: null,
  data: {},
}

export const OrderContext = React.createContext<{
    state: OrderState;
    dispatch: React.Dispatch<OrderAction>;
  }>({ state: initialState } as any)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function OrderContextProvider(props: any) {
  const [
    state,
    dispatch,
  ] = useReducer(reducer, initialState)

  useEffect(() => {
    async function fetchSavedOrder() {
      const savedOrderState = await orderStore.get()

      const currentPremise = await premiseStore.get()

      if (
        savedOrderState &&
          savedOrderState.data.sprn === String(currentPremise?.sprn)
      ) {
        dispatch({
          type: 'replace-state',
          payload: savedOrderState,
        })
      }

      // Replace (empty) state of package step if costumer has already chosen product
      if (savedOrderState?.product && savedOrderState.step === 'package') {
        dispatch({
          type: 'replace-state',
          payload: savedOrderState,
        })
      }
    }

    fetchSavedOrder()
  }, [])
  useEffect(() => {
    async function persistOrderState() {
      if (state !== initialState && state.product) {
        await orderStore.set(state, 2 * 3600)
      }
    }

    persistOrderState()
  }, [state])

  return (
    <OrderContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  )
}
