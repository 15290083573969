import React, { ErrorInfo } from 'react'
import App, { AppContext } from 'next/app'
import {
  init as SentryInit,
  withScope,
  captureException,
} from '@sentry/browser'
import '../styles/global'
import { OrderContextProvider as LegacyOrderContextProvider } from '../components/OrderFlow/OrderContext'
import { OrderContextProvider } from '../components/NewOrderFlow/OrderContext'
import { GeneralContextProvider } from '../components/GeneralContext/GeneralContext'
import { AuthProvider } from '../hooks/auth'
import { BUILD_ID } from '../utils/build-info'
import { newOrderFlowStore } from '../sessionStorage'
import { ProductsContextProvider } from '../components/ProductsContext/ProductsContext'
import {
  ChannelType, SegmentType,
} from '../utils/commonEnums'
import { ButterCMSComponent } from '../butter'
import {
  handleRedirectIfRequired, getSettings,
} from '../utils/useRedirecter'

SentryInit({
  dsn: 'https://372fc2c80f234793b21979394bfe9d99@sentry.io/1334700',
  enabled: process.env.NODE_ENV !== 'production',
  environment: process.env.NODE_ENV,
  release: BUILD_ID,
})
export default class MyApp extends App {
  static componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.remove()
    }
  }

  componentDidCatch(err: Error, errInfo: ErrorInfo) {
    withScope((scope) => {
      Object.keys(errInfo)
        .forEach((key) => {
        // eslint-disable-next-line security/detect-object-injection
          scope.setExtra(key, (errInfo as any)[key])
        })

      captureException(err)
    })

    super.componentDidCatch(err, errInfo)
  }

  async clearCacheData(): Promise<void> {
    const cacheNames = await caches.keys()
    if (cacheNames.length > 0) {
      await Promise.all(cacheNames.map(name => caches.delete(name)))
    }
  }

  getSegmentAndChannel = (pageProps: any) => {
    const components = pageProps?.bundlesData ? pageProps?.bundlesData?.components : pageProps?.components
    const packageComponent = components?.find((c: ButterCMSComponent) => c.type === 'packages')
    const channel = packageComponent?.channel?.toUpperCase() || ChannelType.DIGITAL
    const segment = packageComponent?.customer_segment || SegmentType.RESIDENTIAL

    return {
      channel,
      segment,
    }
  }

  componentDidMount() {
    const hours = 24
    const now = new Date()
      .getTime()
    const basketExpiryTime = localStorage.getItem('basketExpiryTime')

    if (basketExpiryTime && now - parseInt(basketExpiryTime, 10) > hours * 60 * 60 * 1000) {
      newOrderFlowStore.delete()
      localStorage.removeItem('basketExpiryTime')
      localStorage.removeItem('access_token')
    }

    this.clearCacheData()
  }

  render() {
    const {
      Component, pageProps,
    } = this.props

    const {
      channel, segment,
    } = this.getSegmentAndChannel(pageProps)

    return (
      <LegacyOrderContextProvider>
        <OrderContextProvider>
          <AuthProvider>
            <GeneralContextProvider>
              <ProductsContextProvider
                customer_segment={segment}
                channel={channel}
              >
                <Component {...pageProps}/>
              </ProductsContextProvider>
            </GeneralContextProvider>
          </AuthProvider>
        </OrderContextProvider>
      </LegacyOrderContextProvider>
    )
  }
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const ctx = appContext?.ctx
  const currentPath = ctx.asPath
  const settings = await getSettings()

  handleRedirectIfRequired(ctx, settings, currentPath!)

  return {
    ...appProps,
  }
}
